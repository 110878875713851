<template>
  <div class="w-full text-center" v-if="loadingStatuses.getRosterDetails">
    <asom-icon icon="spinner" class="animate-spin" />
  </div>
  <div v-else class="space-y-4 staff_replace">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card v-if="filter.officer">
      <div class="space-y-5">
        <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
          <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
            <asom-form-field label="Officer" required :state="inputStates('filter.officer')" error="Select an officer">
              <asom-input-select v-model="filter.officer" disabled :state="inputStates('filter.officer')"
                :options="[]" />
            </asom-form-field>
            <asom-form-field label="DWS" :state="inputStates('filter.dws')">
              <asom-input-select v-model="filter.dws" :disabled="true" :options="dwsOptions" track-by="dws"
                :state="inputStates('filter.dws')" />
            </asom-form-field>
          </div>
          <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
            <asom-form-field label="Line" required :state="inputStates('filter.line')" error="Select a line">
              <asom-input-select disabled v-model="filter.line" :options="lineOptions"
                :state="inputStates('filter.line')" />
            </asom-form-field>
            <asom-form-field label="Station" required :state="inputStates('filter.station')" error="Select a station">
              <asom-input-select disabled v-model="filter.station" :options="stationOptions"
                :state="inputStates('filter.station')" />
            </asom-form-field>
          </div>
          <asom-form-field label="Merge/Separate" :state="inputStates('filter.dws')" v-if="isWholeRosterUnavailable">
            <asom-input-radio-group @change="inputStatus" class="mt-3" horizontal v-model="filter.inputType" :disabled="radioDisabled"
              :options="inputTypes" />
          </asom-form-field>

          <!-- 整班 -->
          <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4" v-if="!filter.inputType">
            <asom-form-field label="Shift Starting Time" required :state="inputStates('filter.dateTimeFrom')"
              error="Please provide start date and time">
              <asom-input-date-time disabled v-model="filter.dateTimeFrom"
                :state="inputStates('filter.dateTimeFrom')" />
            </asom-form-field>
            <asom-form-field label="Shift Ending Time" required :state="inputStates('filter.dateTimeTo')"
              error="Please provide end date and time">
              <asom-input-date-time disabled v-model="filter.dateTimeTo" :state="inputStates('filter.dateTimeTo')" />
            </asom-form-field>
          </div>

          <!-- 分班次 -->
          <div v-else>
            <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
              <asom-form-field label="Shift Starting Time" required :state="inputStates('filter.dateTimeFrom')"
                error="Please provide start date and time">
                <asom-input-date-time disabled v-model="segmentTime.beforeTimeStart"
                  :state="inputStates('filter.dateTimeFrom')" />
              </asom-form-field>
              <asom-form-field label="Shift Ending Time" required :state="inputStates('filter.dateTimeTo')"
                error="Please provide end date and time">
                <asom-input-date-time disabled v-model="segmentTime.beforeTimeEnd"
                  :state="inputStates('filter.dateTimeTo')" />
              </asom-form-field>
            </div>

            <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
              <asom-form-field label="Shift Starting Time" required :state="inputStates('filter.dateTimeFrom')"
                error="Please provide start date and time">
                <asom-input-date-time disabled v-model="segmentTime.afterTimeStart"
                  :state="inputStates('filter.dateTimeFrom')" />
              </asom-form-field>
              <asom-form-field label="Shift Ending Time" required :state="inputStates('filter.dateTimeTo')"
                error="Please provide end date and time">
                <asom-input-date-time disabled v-model="segmentTime.afterTimeEnd"
                  :state="inputStates('filter.dateTimeTo')" />
              </asom-form-field>
            </div>
          </div>

        </div>
      </div>
    </asom-card>
    <template v-if="!error && !assignResult">
      <div class="w-full text-center" v-if="loadingStatuses.searchReplacements">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
      <asom-card v-else-if="!filter.inputType">
        <div class="space-y-4">
          <asom-alert title="Select only 1 officer">
            <p class="text-sm text-blue-700 pt-2">
              Note: consecutive working day includes the day selected for
              replacement.
            </p>
          </asom-alert>
          <officer-selection :officers="searchData" v-model="assignedOfficer" v-if="!filter.inputType" />
        </div>
        <template #footer>
          <asom-button text="Assign" @click="assignOTAsync" :loading="loadingStatuses.assignNewRoster"
            :disabled="isAssignBtnDisabled" />
        </template>
      </asom-card>

      <!-- 分班次 -->
      <asom-card v-else>
        <div class="space-y-4">
          <asom-alert title="Please select a replacement officer"></asom-alert>
          <officer-selection v-if="!beforeSearch" :officers="BeforeSearchData" v-model="beforeOfficer" />
          <div v-else style=" padding: 20px; border-radius: 6px; background-color: #fafafa; box-shadow:  0 1px 3px 0 rgba(0, 0, 0, 0.1)">
            First half arrangement:
            <p class="text-sm font-black pt-2">
              OFFICER: &nbsp;&nbsp;
              {{ beforeSearch.officerName }}
            </p>
            <p class="text-sm font-black pt-2">
              TIME: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {{ this.statusTime(beforeSearch.startTime) }} -- {{ this.statusTime(beforeSearch.endTime) }}
            </p>
          </div>
          <officer-selection v-if="!afterSearch" :officers="afterSearchData" v-model="afterOfficer" />
          <div v-else style=" padding: 20px; border-radius: 6px; background-color: #fafafa; box-shadow:  0 1px 3px 0 rgba(0, 0, 0, 0.1)">
            Arrangement of the second half:
            <p class="text-sm font-black pt-2">
              OFFICER: &nbsp;&nbsp;
              {{ afterSearch.officerName }}
            </p>
            <p class="text-sm font-black pt-2">
              TIME: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {{ this.statusTime(afterSearch.startTime) }} -- {{ this.statusTime(afterSearch.endTime) }}
            </p>
          </div>
        </div>
        <template #footer>
          <asom-button text="Assign" @click="assignOTAsync" :loading="loadingStatuses.assignNewRoster"
            :disabled="isAssignBtnDisabled" />
        </template>
      </asom-card>
    </template>


  </div>
</template>

<script>
import { nanoid } from "nanoid";
import { mapGetters } from "vuex";
import moment from "moment";
import get from "lodash.get";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import {
  getRosterGap,
  getReplacementSuggestions,
  assignOT,
  onefortwohalfOT,
  assignSpare,
  assignExtendShift,
  // getLineDws,
} from "@/services/manpower.service";
import { parseDateTime, displayTime } from "@/helpers/dateTimeHelpers";
import { ASSIGN_OT, ASSIGN_SPARE, COVERING_SHIFT_SAME_STATION } from "../../../constants/apis";
import OfficerSelection from "./OfficerSelection.vue";
import AsomAlert from "../../../components/alert/AsomAlert.vue";

export default {
  components: {
    OfficerSelection,
    AsomAlert,
  },
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      filter: {
        officer: null,
        station: null,
        line: null,
        dws: null,
        inputType: 0,
        dateTimeFrom: null,
        dateTimeTo: null,
      },
      segmentTime: {
        beforeTimeStart: null,
        beforeTimeEnd: null,
        afterTimeStart: null,
        afterTimeEnd: null
      },
      rosterInfo: {
        planDate: null,
        unavailability: false,
      },
      onExtensionNeedBackward: false,
      onExtensionNeedForward: false,
      searchData: [],

      isWholeRosterUnavailable: false,
      BeforeSearchData: [], // for before shift
      afterSearchData: [],  // for after shift
      radioDisabled: false,
      beforeSearch: null,
      afterSearch: null,

      assignedOfficer: null,
      beforeOfficer: null,
      afterOfficer: null,

      dwsOptions: [],
      isLoadingRosterDetails: false,
      loadingStatuses: {
        getRosterDetails: false,
        searchReplacements: false,
        assignNewRoster: false,
      },
      extensionShiftTime: {
        priorShiftEndDate: null,
        afterShiftStartDate: null,
      },
      showModal: false,
      error: null,
      assignResult: null,
      isOTAssignmentSucceeded: false,

      inputTypes: [
        { value: 0, label: "put together", timeObj: ['beforeTimeStart', 'beforeTimeEnd'] },
        { value: 1, label: "respectively", timeObj: ['afterTimeStart', 'afterTimeEnd'] },
      ]
    };
  },
  validations() {
    return {
      filter: {
        officer: { required },
        station: { required },
        line: { required },
        dws: { required },
        inputType: { required },
        dateTimeFrom: { required },
        dateTimeTo: { required },
      },
    };
  },
  mounted() {
    this.v$ && this.v$.$reset();
    this.loadData();
  },
  watch: {
    "filter.dws": function (newValue) {
      if (newValue && newValue.shiftStart && newValue.shiftEnd) {
        const _planStart =
          this.rosterInfo.planDate.split(" ")[0] + " " + newValue.shiftStart;
        const _planEnd =
          this.rosterInfo.planDate.split(" ")[0] + " " + newValue.shiftEnd;
        this.filter.dateTimeFrom = moment(
          _planStart,
          "DD/MM/YYYY HH:mm:ss"
        ).toDate();
        this.filter.dateTimeTo = moment(
          _planEnd,
          "DD/MM/YYYY HH:mm:ss"
        ).toDate();
      }
    },
  },
  computed: {
    ...mapGetters({
      lineOptions: "smrtResource/lineOptions",
      stationOptions: "smrtResource/stationOptions",
    }),
    needBothExtensions() {
      return this.onExtensionNeedForward && this.onExtensionNeedBackward;
    },
    assignValidationError() {
      let error = null;
      if (!this.filter.inputType) {
        if (!this.assignedOfficer || !this.assignedOfficer.officerId) error = "Select only 1 officer for replacement.";
      } else {
        if ((!this.beforeOfficer || !this.beforeOfficer.officerId) && (!this.afterOfficer || !this.afterOfficer.officerId)) error = "Please select a replacement officer.";
      }
      return error;
    },
    apiForAssignment() {
      if (this.assignValidationError) return null;
      const dws = get(this.assignedOfficer, "dws", "");
      switch (dws.toLowerCase()) {
        case "spare":
          return ASSIGN_SPARE;
        case "off":
        case "rest":
          return ASSIGN_OT;
        default:
          return COVERING_SHIFT_SAME_STATION;
      }
    },
    dataForAssignment() {
      const inputType = this.filter.inputType;
      if (!inputType) {
        switch (this.apiForAssignment) {
          case ASSIGN_OT:
            return {
              officerId: this.assignedOfficer.officerId,
              stationId: get(this.filter.station, "value", null),
              lineId: get(this.filter.line, "value", null),
              dws: this.filter.dws.dws,
              planDate: parseDateTime(this.rosterInfo.planDate),
              shiftStartTime: parseDateTime(this.filter.dateTimeFrom),
              shiftEndTime: parseDateTime(this.filter.dateTimeTo),
              replacingRosterId: this.$route.params.rosterId,
            };

          case ASSIGN_SPARE:
            return {
              officerId: this.assignedOfficer.officerId,
              stationId: get(this.filter.station, "value", null),
              lineId: get(this.filter.line, "value", null),
              dws: this.filter.dws.dws,
              planDate: parseDateTime(this.rosterInfo.planDate),
              replacingRosterId: this.$route.params.rosterId,
            };
          case COVERING_SHIFT_SAME_STATION:
            return {
              officerId: this.assignedOfficer.officerId,
              planDate: parseDateTime(this.rosterInfo.planDate),
              coveringRosterId: this.$route.params.rosterId,
            }
          default:
            return {};
        }
      } else {
        const beforeId = this.beforeOfficer?.officerId ?? null;
        const afterId = this.afterOfficer?.officerId ?? null;
        const first = {
          officerId: beforeId,
          stationId: get(this.filter.station, "value", null),
          lineId: get(this.filter.line, "value", null),
          dws: this.filter.dws.dws,
          planDate: parseDateTime(this.rosterInfo.planDate),
          shiftStartTime: parseDateTime(this.segmentTime.beforeTimeStart),
          shiftEndTime: parseDateTime(this.segmentTime.beforeTimeEnd),
          replacingRosterId: this.$route.params.rosterId,
        };
        const second = {
          officerId: afterId,
          stationId: get(this.filter.station, "value", null),
          lineId: get(this.filter.line, "value", null),
          dws: this.filter.dws.dws,
          planDate: parseDateTime(this.rosterInfo.planDate),
          shiftStartTime: parseDateTime(this.segmentTime.afterTimeStart),
          shiftEndTime: parseDateTime(this.segmentTime.afterTimeEnd),
          replacingRosterId: this.$route.params.rosterId,
        }
        
        return {
          fisrtHalfOtAssignmentRequest: beforeId ? first : null,
          secondHalfOtAssignmentRequest: afterId ? second : null,
        }
      }
    },
    isAssignBtnDisabled() {
      return (
        this.loadingStatuses.assignNewRoster || Boolean(this.assignValidationError)
      );
    },
  },
  methods: {
    getDwsLabel(dws, shiftStart, shiftEnd) {
      return (
        dws +
        (shiftStart && shiftEnd
          ? " (" + shiftStart + " - " + shiftEnd + ")"
          : "")
      );
    },

    inputStatus() { this.assignValidationError; },
    divideTime() {
      const filter = this.filter;
      const start = moment(filter.dateTimeFrom);
      const end = moment(filter.dateTimeTo);
      let mid = moment(filter.dateTimeFrom);
      mid.add(end.diff(start) / 2);
      this.segmentTime.beforeTimeStart = filter.dateTimeFrom;
      this.segmentTime.beforeTimeEnd = this.segmentTime.afterTimeStart = mid.toDate();
      this.segmentTime.afterTimeEnd = filter.dateTimeTo;
    },

    statusTime(time) {
      return time ? displayTime(time) : null;
    },

    setRosterDetails({
      roster: {
        officerId,
        officer,
        station,
        line,
        planDate,
        planStart,
        planEnd,
        dws,
        dwsText,
        unavailability = false,
      },
      coverageRequirementStart,
      coverageRequirementEnd,
      onExtensionNeedBackward = false,
      onExtensionNeedForward = false,
    }) {
      if (officerId && officer) {
        this.filter.officer = {
          label: officer,
          value: officerId,
        };
      }
      this.filter.station = {
        label: get(station, "stationName", null),
        value: get(station, "stationId", null),
      };
      this.filter.line = {
        label: get(line, "lineName", null),
        value: get(line, "lineId", null),
      };
      if (coverageRequirementStart)
        this.filter.dateTimeFrom = moment(
          coverageRequirementStart,
          "DD/MM/YYYY HH:mm:ss"
        ).toDate();
      if (coverageRequirementEnd)
        this.filter.dateTimeTo = moment(
          coverageRequirementEnd,
          "DD/MM/YYYY HH:mm:ss"
        ).toDate();
      this.filter.dws = {
        label: this.getDwsLabel(
          dws,
          planStart ? planStart.split(" ")[1] : null,
          planEnd ? planEnd.split(" ")[1] : null
        ),
        dwsText: dwsText,
        dws: dws,
      };
      this.rosterInfo.planDate = planDate;
      this.rosterInfo.unavailability = unavailability;
      this.onExtensionNeedBackward = onExtensionNeedBackward;
      this.onExtensionNeedForward = onExtensionNeedForward;
    },
    async loadData() {
      if (this.$route.params.rosterId) {
        this.loadingStatuses.getRosterDetails = true;
        const result = await getRosterGap(this.$route.params.rosterId);
        if (result.success) {
          this.setRosterDetails(get(result, "payload", {}));
          // console.log('查询---------',result.payload);
          const {isOneForTwoHalfReplaced, firstReplacedRoster, secondReplacedRoster, roster} = result.payload;
          this.isWholeRosterUnavailable = roster.isWholeRosterUnavailable;
          if(isOneForTwoHalfReplaced) {
            this.radioDisabled = true;
            this.filter.inputType = 1;
            // console.log('结果------',firstReplacedRoster, secondReplacedRoster);
            this.beforeSearch = firstReplacedRoster;
            this.afterSearch = secondReplacedRoster;
          }
          this.$nextTick(() => {
            this.search();
          });
        } else {
          this.error = "Failure to get roster details";
          this.$scrollTop();
        }
        this.loadingStatuses.getRosterDetails = false;
      } else {
        this.error = "No Roster Found.";
        this.$scrollTop();
      }
      this.divideTime();
      // console.log('beforeSearch, afterSearch------',this.beforeSearch, this.afterSearch);
      if(!this.beforeSearch && this.isWholeRosterUnavailable) this.search(this.inputTypes[0]);
      if(!this.afterSearch && this.isWholeRosterUnavailable) this.search(this.inputTypes[1]);
    },
    async search(arr) {
      // if (this.loadingStatuses.searchReplacements) return;
      this.error = "";
      if (!arr) {
        this.v$.$reset();
        this.v$.$touch();
      }
      if (!this.v$.$invalid) {
        this.loadingStatuses.searchReplacements = true;
        this.searchData = [];
        this.BeforeSearchData = [];
        this.afterSearchData = [];
        let dateTimeFrom = this.filter.dateTimeFrom;
        let dateTimeTo = this.filter.dateTimeTo;
        if (arr) {
          dateTimeFrom = this.segmentTime[arr.timeObj[0]];
          dateTimeTo = this.segmentTime[arr.timeObj[1]];
        }
        const result = await getReplacementSuggestions({
          rosterId: this.$route.params.rosterId,
          officerId: this.filter.officer.value,
          lineId: get(this.filter.line, "value", null),
          stationId: get(this.filter.station, "value", null),
          dateTimeFrom: parseDateTime(dateTimeFrom),
          dateTimeTo: parseDateTime(dateTimeTo),
        });
        if (result.success && Array.isArray(get(result, "payload.officers"))) {
          if (!arr) {
            this.extensionShiftTime.priorShiftEndDate = this.filter.dateTimeFrom;
            this.extensionShiftTime.afterShiftStartDate = this.filter.dateTimeFrom;
            this.searchData = get(result, "payload.officers", []).map((item) => ({ ...item, id: nanoid() }));
          } else if (!arr.value) {
            this.BeforeSearchData = get(result, "payload.officers", []).map((item) => ({ ...item, id: nanoid() }));
          } else if (arr.value) {
            this.afterSearchData = get(result, "payload.officers", []).map((item) => ({ ...item, id: nanoid() }));
          }
        }
        this.loadingStatuses.searchReplacements = false;
      } else {
        this.error = "Please complete all required fields";
        if (!arr) this.$scrollTop();
      }
    },
    async assignOTAsync() {
      if (this.assignValidationError) {
        // this.assignResult = this.assignValidationError;
        this.showModal = true;
        return;
      } else {
        const inputType = this.filter.inputType;
        let api = null;
        if (inputType) {
          api = onefortwohalfOT;
        } else {
          switch (this.apiForAssignment) {
            case ASSIGN_OT:
              api = assignOT;
              break;
            case ASSIGN_SPARE:
              api = assignSpare;
              break;
            case COVERING_SHIFT_SAME_STATION:
              api = assignExtendShift;
              break;
            default:
              break;
          }
        }
        if (api) {
          // console.log('底部提交时候传参------------', this.dataForAssignment);
          this.loadingStatuses.assignNewRoster = true;
          this.assignResult = null;
          const result = await api(this.dataForAssignment);
          // console.log('result-----------', result);
          this.loadingStatuses.assignNewRoster = false;
          if (result && result.payload && result.payload.success) {
            this.isOTAssignmentSucceeded = true;
            this.assignResult = "Successfully assign new officer.";
            this.$router.push({ name: "Supervisor Roster" });
          } else {
            this.assignResult = result.payload;
            this.error = result.payload;
            this.$scrollTop();
          }
          this.showModal = true;
        } else {
          this.assignResult = "Cannot find server.";
          this.showModal = true;
        }

      }
    },
    onCloseModal() {
      this.showModal = false;
      if (this.isOTAssignmentSucceeded) {
        this.$router.push({
          name: "Supervisor Roster",
          query: {
            lineId: get(this.filter.line, "value", null),
            stationId: get(this.filter.station, "value", null),
          },
        });
      }
    },
  },
};
</script>

<style scope>
.staff_replace .AsomCard__Body {
  @apply sm:rounded-tr-md sm:rounded-tl-md;
}
</style>
